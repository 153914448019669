<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="fabrics"
            hide-default-footer
            disable-pagination
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Ткани</v-toolbar-title>
                    <v-spacer />
                    <v-btn color="primary" @click="create">
                        <v-icon>mdi-plus</v-icon> Добавить
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.enabled`]="{ item }">
                <span :class="`true-false-label ${getBadge(item.enabled)}`">
                    &nbsp;{{item.enabled ? 'Да' : 'Нет' }}&nbsp;
                </span>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon color="primary" @click="edit(item)">mdi-pencil</v-icon>
            </template>
        </v-data-table>

        <v-dialog
            v-model="editModal"
            width="800px"
        >
            <v-card>
                <v-card-title class="primary">
                    Ткань
                </v-card-title>

                <v-card-text>
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editEntity.code"
                                    outlined
                                    label="Код"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="editEntity.title"
                                    outlined
                                    label="Название"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="d-flex flex-column justify-space-between">
                                <span class="mb-4">
                                    <v-img width="200" v-if="editEntity.picture" :src="imageSrc(editEntity.picture)" />
                                </span>
                                <span>
                                    <v-file-input
                                        label="Картинка"
                                        outlined
                                        prepend-icon="mdi-image"
                                        hide-details
                                        @change="setPicture"
                                    />
                                </span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="editEntity.preview"
                                    outlined
                                    label="Краткое описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="editEntity.description"
                                    outlined
                                    label="Подробное описание"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <v-switch
                                    v-model="editEntity.enabled"
                                    label="Активен"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="editEntity.position"
                                    outlined
                                    label="Сортировка"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn
                        color="primary"
                        class="mr-3"
                        @click="save"
                    >Сохранить</v-btn>
                    <v-btn
                        color="light"
                        class="ml-3"
                        @click="editModal = false"
                    >Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="editEntity.id"
                        color="error"
                        class="ml-3"
                        @click="remove"
                    >Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc } from '../../helpers';

    export default {
        name: 'Sizes',
        components: {
        },
        data: () => ({
            imageSrc,
            editModal: false,
            editEntity: {},
            headers: [
                { text: 'Сортировка', value: 'position' },
                { text: 'Код', value: 'code' },
                { text: 'Заголовок', value: 'title' },
                { text: 'Активен', value: 'enabled' },
                { text: '', align: 'right', value: 'edit' },
            ],
        }),
        computed: {
            ...mapState('fabrics', {
                fabrics: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('fabrics/fetch');
        },
        methods: {
            create() {
                this.editEntity = {
                    code: '',
                    title: '',
                    gender_id: null,
                    chest: '',
                    waist: '',
                    hips: '',
                    position: 0,
                    enabled: true
                };
                this.editModal = true;
            },
            edit(item) {
                this.editEntity = JSON.parse(JSON.stringify(item));
                this.editModal = true;
            },
            async setPicture(file) {
                this.editEntity.picture = await store.dispatch('files/upload', { file });
            },
            async save() {
                try {
                    store.commit('fabrics/SET_ENTITY', this.editEntity);
                    await store.dispatch('fabrics/save', {entity_id: this.$route.params.id});
                    await store.dispatch('fabrics/fetch');
                    this.editModal = false;
                } catch (error) {
                    console.log(error);
                    alert('Ошибка сохранения');
                }
            },
            async remove() {
                try {
                    await store.dispatch('fabrics/delete', {entity_id: this.$route.params.id, id: this.editEntity.id});
                    await store.dispatch('fabrics/fetch');
                    this.editModal = false;
                } catch (error) {
                    alert('Ошибка удаления');
                }
            },
            getBadge (status) {
                return status ? 'success' : 'error';
            },
        }
    }
</script>